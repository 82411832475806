import { Badge } from "flowbite-react";
import React, { InputHTMLAttributes, ForwardRefRenderFunction } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import { IconType } from "react-icons";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  iconLeft?: IconType;
  iconErrorDisplay?: boolean;
  register?: UseFormRegisterReturn;
  label?: string;
  isPro?: boolean;
}

const Input: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  { iconLeft: IconLeft, label, disabled, isPro, ...props },
  ref
) => {
  return (
    <>
      {(props.placeholder || label) && (
        <label
          htmlFor={props.id}
          className="font-medium text-sm my-2 text-gray-700"
        >
          <span>
            {label || props.placeholder}
            {isPro && (
              <Badge size={"xs"} className="inline-block ml-2 text-teal-600">
                PRO
              </Badge>
            )}
          </span>
        </label>
      )}

      <div className="relative px-0.5 mt-1">
        {IconLeft && (
          <span
            className={`absolute left-2.5 top-1/2 -translate-y-1/2 items-center text-gray-500`}
          >
            <IconLeft size={20} />
          </span>
        )}

        <input
          className={`h-8 w-full appearance-none rounded-lg border p-1 ${
            IconLeft ? "pl-8" : "pl-2"
          } pr-4 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-teal-600  ${
            disabled ? "bg-gray-200 cursor-not-allowed" : "bg-transparent"
          }`}
          ref={ref}
          disabled={disabled}
          {...props}
        />
      </div>
    </>
  );
};

export default React.forwardRef(Input);
